// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-maiertech-gatsby-theme-pages-core-src-template-js": () => import("./../../../node_modules/@maiertech/gatsby-theme-pages-core/src/template.js" /* webpackChunkName: "component---node-modules-maiertech-gatsby-theme-pages-core-src-template-js" */),
  "component---node-modules-maiertech-gatsby-theme-tags-core-src-templates-tag-query-js": () => import("./../../../node_modules/@maiertech/gatsby-theme-tags-core/src/templates/tag-query.js" /* webpackChunkName: "component---node-modules-maiertech-gatsby-theme-tags-core-src-templates-tag-query-js" */),
  "component---node-modules-maiertech-gatsby-theme-tags-core-src-templates-tags-query-js": () => import("./../../../node_modules/@maiertech/gatsby-theme-tags-core/src/templates/tags-query.js" /* webpackChunkName: "component---node-modules-maiertech-gatsby-theme-tags-core-src-templates-tags-query-js" */),
  "component---node-modules-undataforum-gatsby-theme-events-core-src-templates-event-query-js": () => import("./../../../node_modules/@undataforum/gatsby-theme-events-core/src/templates/event-query.js" /* webpackChunkName: "component---node-modules-undataforum-gatsby-theme-events-core-src-templates-event-query-js" */),
  "component---node-modules-undataforum-gatsby-theme-events-core-src-templates-events-query-js": () => import("./../../../node_modules/@undataforum/gatsby-theme-events-core/src/templates/events-query.js" /* webpackChunkName: "component---node-modules-undataforum-gatsby-theme-events-core-src-templates-events-query-js" */),
  "component---node-modules-undataforum-gatsby-theme-posts-core-src-templates-post-query-js": () => import("./../../../node_modules/@undataforum/gatsby-theme-posts-core/src/templates/post-query.js" /* webpackChunkName: "component---node-modules-undataforum-gatsby-theme-posts-core-src-templates-post-query-js" */),
  "component---node-modules-undataforum-gatsby-theme-posts-core-src-templates-posts-query-js": () => import("./../../../node_modules/@undataforum/gatsby-theme-posts-core/src/templates/posts-query.js" /* webpackChunkName: "component---node-modules-undataforum-gatsby-theme-posts-core-src-templates-posts-query-js" */),
  "component---src-pages-data-solutions-js": () => import("./../../../src/pages/data-solutions.js" /* webpackChunkName: "component---src-pages-data-solutions-js" */),
  "component---src-pages-ensuring-continuity-js": () => import("./../../../src/pages/ensuring-continuity.js" /* webpackChunkName: "component---src-pages-ensuring-continuity-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-data-js": () => import("./../../../src/pages/open-data.js" /* webpackChunkName: "component---src-pages-open-data-js" */),
  "component---src-pages-working-remotely-js": () => import("./../../../src/pages/working-remotely.js" /* webpackChunkName: "component---src-pages-working-remotely-js" */)
}

