import { Grid, Link } from '@undataforum/gatsby-theme-theme-ui';
import EnsuringContinuityIcon from "../../../../src/components/ensuring-continuity-icon";
import DataSolutionsIcon from "../../../../src/components/data-solutions-icon";
import OpenDataIcon from "../../../../src/components/open-data-icon";
import SharingIcon from "../../../../src/components/sharing-icon";
import WebLinksIcon from "../../../../src/components/web-links-icon";
import WorkingRemotelyIcon from "../../../../src/components/working-remotely-icon";
import * as React from 'react';
export default {
  Grid,
  Link,
  EnsuringContinuityIcon,
  DataSolutionsIcon,
  OpenDataIcon,
  SharingIcon,
  WebLinksIcon,
  WorkingRemotelyIcon,
  React
};